<template>
  <svg id="grid" viewBox="0 0 2 2">
    <rect width="1" height="1" />
    <rect width="1" height="1" />
    <rect width="1" height="1" />
    <rect width="1" height="1" />
  </svg>
</template>
<style lang="stylus">
  svg#grid
    width: 100%
    height: 100%
</style>
